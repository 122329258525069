<template>
  <div class="bm-menu" :style="width">
    <div class="p-2">
      <div class="text-right p-3">
        <font-awesome-icon
          class="iconeFechar"
          @click="closeMenu()"
          icon="times"
        />
      </div>
      <h4 class="m-2">Olá, {{ nome }}</h4>

      <hr />
      <nav class="bm-item-list">
        <router-link :to="'/dashboard'">
          <i class="fa text-white fa-fw fa-home"></i>
          <span>Home </span>
        </router-link>
        <!-- <router-link :to="'/cashback'"> -->
         <a href="https://clubecerto.com.br/cashback">
          <i style="padding-left: 2px" class="fa text-white fa-money"></i>
          <span style="padding-left: 2px">CashCerto</span>
        </a>
        <!-- </router-link> -->
        <router-link :to="'/favoritos'">
          <i class="fa text-white fa-fw fa-star-o"></i>
          <span>Favoritos</span>
        </router-link>
         <router-link :to="'/cartaoVirtual'">
          <i class="fa text-white fa-fw fa-id-card-o"></i>
          <span>Cartão Virtual</span>
        </router-link>
        <!-- <router-link :to="'/econometro'"> -->
         <!-- <router-link :to="'/econometro'"> -->
        <!-- <a>
          <i style="opacity: 0.7; padding-left: 2px;" class="fa fa-tachometer text-white"></i>
          <span style="opacity: 0.7;padding-left: 2px">Econômetro</span>
          </a> -->
        <!-- </router-link> -->
        <!-- </router-link> -->
        <a
          href="javascript:void(0)"
          @click="openModal"
          v-if="empresas.length > 0"
        >
          <i class="fa text-white fa-fw fa-refresh"></i>
          <span>Trocar de Empresa</span>
        </a>
        <router-link :to="'/sobre'">
          <i class="fa text-white fa-fw fa-info-circle"></i>
          <span>Sobre</span>
        </router-link>
        <router-link :to="'/ajuda'">
          <i class="fa text-white fa-fw fa-question-circle"></i>
          <span>Ajuda</span>
        </router-link>
        <span  @click="logout">
          <i class="fa text-white fa-fw fa-power-off"></i>
          <span>Sair</span>
        </span>
      </nav>
      <div class="logoBottom">
        <img src="./../assets/images/logo-white.png" width="60%" />
      </div>
    </div>
    <b-modal
      v-model="modalEmpresas"
      hide-footer
      centered
      title="Selecione uma Empresa para Efetuar Login"
      ><div class="mb-5">
        <b-card
          v-for="item in empresas"
          :key="item.id"
          no-body
          class="overflow-hidden mb-3"
        >
          <b-row no-gutters>
            <b-col class="text-center p-5">
              <img
                :src="item.Empresa.Imagem"
                :alt="item.Empresa.Nome"
                class="rounded-0"
                width="70%"
                @click="selecionaEmpresa(item)"
              />
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from "jsonwebtoken";
export default {
  name: "DashBoard",
  props: {
    openMenuLateral: Boolean,
  },
  watch: {
    openMenuLateral() {
      if (this.openMenuLateral == true) this.openMenu();
    },
  },
  data() {
    return {
      width: { width: "0px" },
      temaCor: { CorBackgroundCabecalho: null, CorItensCabecalho: null },
      nome: "",
      modalEmpresas: false,
      empresas: [],
    };
  },
  methods: {
    formataNome(nome) {
      var aux = "";
      if (nome) aux = nome.split(" ")[0];
      return aux;
    },
    openMenu() {
      this.width.width = "90%";
    },
    closeMenu() {
      this.width.width = "0px";
      this.$emit("closeMenuLateral", true);
    },
    selecionaEmpresa(item) {
      this.modalEmpresas = false;
      localStorage.setItem("nome", item.Nome);
      localStorage.setItem("cpf", item.CPF);
      localStorage.setItem("dadosEmpresa", JSON.stringify(item.Empresa));
      this.$forceUpdate();
      this.$router.push({
        path: "dashboard",
        query: { plan: new Date().toString() },
      });
    },
    openModal() {
      this.modalEmpresas = true;
    },
    verificaToken() {
      var dadosEmpresa = JSON.parse(localStorage.getItem("dadosEmpresa"));
      const nome = localStorage.getItem("nome");
      this.temaCor.topoApp = dadosEmpresa.CorBackgroundCabecalho;
      console.log(this.temaCor);
      this.logo = dadosEmpresa.Imagem;
      var empresas = localStorage.getItem("empresas");
      if (empresas) this.empresas = JSON.parse(empresas);

      const token = localStorage.getItem("user-token");
      if (token) {
        this.decoded = jwt.decode(token);
        this.nome = this.formataNome(nome);
      }
    },
    logout(){
      localStorage.removeItem("user-token");
      localStorage.removeItem("dadosEmpresa");
      localStorage.removeItem("cpf");
      
      this.$router.push({
        path: "/",
        query: { plan: new Date().toString() },
      });
    }
  },
  mounted() {
    this.verificaToken();
  },
  components: {},
};
const newLocal = "\u0066\u0061\u0020\u0074\u0065\u0078\u0074\u002d\u0077\u0068\u0069\u0074\u0065\u0020\u0066\u0061\u002d\u0066\u0077\u0020\u0066\u0061\u002d\u0073\u0074\u0061\u0072\u002d\u006f";
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  overflow-x: hidden;
}

.iconeFechar {
  font-size: 28px;
}
.bm-menu {
  background: #373a47;
}
.bm-menu {
  background-color: #3f3f41;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transition: 0.2s;
  width: 0;
  z-index: 1039;
  color: #fff;
}
.bm-item-list > * {
  display: flex;
  padding: 0.7em;
  text-decoration: none;
}
.bm-item-list > * > span {
  color: #fff;
  font-weight: 700;
  margin-left: 10px;
}
.bm-item-list > * > i {
  font-weight: 700;
  margin-left: 10px;
  font-size: 18px;
}
.logoBottom {
  margin-top: 50px;
  text-align: center;
  opacity: 0.5;
}
</style>
