<template>
  <div class="">
    <div class="topo p-2 pb-1" :style="`background-color:${temaCor.topoApp}`">
      <!--b-row>
        <b-col>
          <font-awesome-icon icon="times" />
        </b-col>
        <b-col class="text-right">
          <font-awesome-icon :icon="['fa', 'question-circle']" class="mr-2" />Ajuda
        </b-col>
      </b-row-->

      <b-row class="my-1">
        <b-col
          style="
            background-color: #fff;
            border-radius: 5px;
            margin: 0px 15px;
            padding: 10px 0;
          "
        >
          <b-row>
            <b-col cols="6" class="text-center">
              <router-link :to="'/dashboard'"
                ><img height="90" :src="logo"
              /></router-link>
            </b-col>
            <b-col class="text-right mr-3">
              <div @click="openMenuLateral()" :class="`icone mb-2 `">
                <font-awesome-icon
                  :icon="['fa', 'bars']"
                  class="text-secondary"
                />
              </div>
              Olá, <span class="nome">{{ nome }}</span></b-col
            >
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<!-- eslint-disable -->
<script>
import jwt from "jsonwebtoken";
export default {
  name: "DashBoard",

  data() {
    return {
      icone: "faSpinner",
      decoded: {},
      logo: "https://bboneapp.s3.amazonaws.com/evo-color.png",
      styleTamanho: {
        height: "350px",
      },
      temaCor: {},
      nome: "",
    };
  },
  methods: {
    formataNome(nome) {
      var aux = "";
      if (nome) aux = nome.split(" ")[0];
      return aux;
    },
    openMenuLateral() {
      this.$emit("OpenMenuLateral", true);
    },
    openDecoded() {
      var dadosEmpresa = JSON.parse(localStorage.getItem("dadosEmpresa"));
      const nome = localStorage.getItem("nome");
      this.temaCor.topoApp = dadosEmpresa.CorBackgroundCabecalho;
      console.log(this.temaCor);
      this.logo = dadosEmpresa.Imagem;
      const token = localStorage.getItem("user-token");
      if (token) {
        this.decoded = jwt.decode(token);
        this.nome = this.formataNome(nome);
      }
    },
  },
  mounted() {
    this.openDecoded();
  },
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  overflow-x: hidden;
  background: none !important;
}

.topo {
  font-size: 11px;
  height: 130px;
}

.topo .nome {
  font-size: 16px;
  font-family: "robotobold";
}

.topo .icone {
  vertical-align: middle;
  font-size: 28px;
}

#app {
  margin-top: 0;
}
.iconeChart {
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}
.iconeChart img {
  width: 100%;
}
.avatar {
  width: 80px;
  height: 80px;
  /*position: relative;*/
}
.nota {
  margin-top: -10px;
  /* clear: both;
  position: relative;*/
}
.topo h3 {
  font-size: 15px;
  font-weight: bold;
}

.topo h5 {
  font-size: 11px;
}
</style>
